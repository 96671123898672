@import 'constants';
@import 'colors';
@import 'layout';
@import 'fonts';
@import 'mixins';

* {
  font-family: $ff-rosatom;
}

html {
  font-size: 62.5%;
}

html,
body,
#root {
  height: unset;
  min-height: 100%;
  background-color: $bg;
}

body {
  margin: 0;
  padding: 0;
  font-family: $ff-rosatom;
  color: var(--color-primary);
  font-size: inherit !important;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: $secondary;
  border-radius: 1rem;
}

body h1 {
  font-weight: bold;
}

span.ant-typography a {
  color: $rosatom-light-blue;
  &:hover {
    color: $primary-active;
  }
}
#root > .ant-layout {
  max-width: 1172px;
  margin: 0 auto;
}

//dynamical scrollbar
.dy-scrollbar-off{
  transition: all 1s ease-out;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.dy-scrollbar-on{
  transition: all 1s ease-out;
  &::-webkit-scrollbar-thumb {
    background-color: #BCBED2;
  }
}

.scrollbar{
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #BCBED2;
  }
}
