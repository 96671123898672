@mixin media-breakpoint-xs($direction: up) {
  @if $direction == up {
    @media screen and (min-width: 480px) {
      @content;
    }
  }
  @else  {
    @media screen and (max-width: 479px) {
      @content;
    }
  }
}

@mixin media-breakpoint-sm($direction: up) {
  @if $direction == up {
    @media screen and (min-width: 576px) {
      @content;
    }
  }
  @else  {
    @media screen and (max-width: 575px) {
      @content;
    }
  }
}

@mixin media-breakpoint-md($direction: up) {
  @if $direction == up {
    @media screen and (min-width: 768px) {
      @content;
    }
  }
  @else  {
    @media screen and (max-width: 767px) {
      @content;
    }
  }
}

@mixin media-breakpoint-lg($direction: up) {
  @if $direction == up {
    @media screen and (min-width: 992px) {
      @content;
    }
  }
  @else  {
    @media screen and (max-width: 991px) {
      @content;
    }
  }
}

@mixin media-breakpoint-xl($direction: up) {
  @if $direction == up {
    @media screen and (min-width: 1200px) {
      @content;
    }
  }
  @else  {
    @media screen and (max-width: 1199px) {
      @content;
    }
  }
}