@import "../../../styles/colors.scss";

.ant-popover {

  &.visits {
    width: 640px;
  }
  &.visits .ant-popover-content,
  &.visits .ant-popover-inner {
    width: 632px;
    height: auto;
    max-height: 400px;
  }

  &.downloads {
    width: 360px;
  }
  &.downloads .ant-popover-content,
  &.downloads .ant-popover-inner {
    width: 352px;
    height: auto;
    max-height: 418px;
  }
}

.ant-popover-title {
  padding: 0 8px;
  border-bottom: 0;
}

.ant-typography h2, h2.ant-typography {
  color: $dark-blue-s;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.ant-popover-inner-content {
  padding: 0;
  margin-top: 10px;
}

.ant-popover-inner {
  border-radius: 10px;
  padding: 24px 16px;
  box-shadow: 0 4px 20px 2px #00000029, 0 1px 2px 0 #00000014;
}

.ant-table-thead>tr>th {
  background-color: $white;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  color: $dark-blue-s;
  padding: 13px 8px 33px 8px;
  border-color: $gray6;
}

.ant-table-tbody>tr>td {
  border-color: $gray6;
  padding: 21px 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background-color: transparent;
}

.ant-table-body {
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray5;
    border-radius: 100px;
  }
}