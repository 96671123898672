@import '../../../styles/constants';

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 161px;
  padding: 20px 16px;
  border: 0.5px solid $secondary;
  border-radius: 1rem;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.06);
  position: relative;
}

.cardFile {
  background-color: $white;
}

.cardFileOpacity {
  opacity: 0.3;
}

.cardFolder {
  background-color: $bg;
}

.cardFolderActive {
  border: 0.5px solid $rosatom-light-blue;
}

.titleRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.title {
  margin-bottom: 5px;
  margin-right: 5px;
  color: $rosatom-deep-blue;
}

.txtSize {
  color: $dark-gray;
  font-size: 12px;
  line-height: 1.3em;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: auto;
}

.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.statisticButton {
  width: 36px;
  height: 36px;
  padding: 6px;
}

.icon {
  width: 36px;
  cursor: pointer;
}

.btnLink {
  height: 36px;
  width: 87px;
  background-color: $primary;
  border-radius: 1rem;
  color: $white;
  padding: 0 !important;

  span {
    width: 100%;
    font-size: 12px;
    line-height: 36px;
    text-transform: uppercase;
  }
}

a.btnLink {
  padding: 0 !important;
}

h4.title {
  margin-bottom: 0.5rem;
  font-size: 17px;
  line-height: 1.3em;
  word-break: break-word;
  color: $black;
}

.newDock {
  border-color: $orange2;

  &::before {
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $orange2;
    position: absolute;
    top: 7px;
    right: 8px;
  }
}
