@import '../../../styles/mixins/media-queries';
@import '../../../styles/constants.scss';

.form {
  margin-top: 20px;
  
  flex-basis: 100%;
  padding-right: 10px;
  padding-left: 2px;
  
  @include media-breakpoint-sm(up) {
    max-width: 328px;
  }

  @include media-breakpoint-md(up) {
    padding-right: 0px;
  }

  @include media-breakpoint-xl(up) {
    flex-basis: 328px;
  }
}

.spin {
  top: calc(50% - 200px)!important;
}

.submitButton {
  width: 100%;
  @include media-breakpoint-xl(up) {
    width: auto;
  }
}