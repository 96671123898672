@import "../../../../../../../styles/constants";

.radioGroup {
  width: 100%;
  display: inherit !important;
}

.customOptionInput {
  border: 0.5px solid rgba(0, 0, 0, 0.12);
}
