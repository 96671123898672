@font-face {
  font-family: 'Rosatom';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src:
          url('../assets/fonts/RST45.ttf') format('truetype'),
          url('../assets/fonts/RST45.woff2') format('woff2'),
          url('../assets/fonts/Rosatom-Light.woff') format('woff');
}

@font-face {
  font-family: 'Rosatom';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
          url('../assets/fonts/RST55.ttf') format('truetype'),
          url('../assets/fonts/RST55.woff2') format('woff2'),
          url('../assets/fonts/Rosatom-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Rosatom';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src:
          url('../assets/fonts/RST75.ttf') format('truetype'),
          url('../assets/fonts/RST75.woff2') format('woff2'),
          url('../assets/fonts/Rosatom-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Rosatom';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src:
          url('../assets/fonts/RST56.ttf') format('truetype'),
          url('../assets/fonts/RST56.woff2') format('woff2'),
          url('../assets/fonts/Rosatom-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
          url('../assets/fonts/gilroy-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src:
          url('../assets/fonts/gilroy-bold.ttf') format('truetype');
}

$ff-rosatom: 'Rosatom', 'Ubuntu', 'Arial', sans-serif;
