@import '../../../styles/constants.scss';

.ant-input,
.ant-input-password {
  font-size: 16px;
  width: 100%;
  min-height: 44px;
  border-radius: 0.8rem;
  background-color: $lighten-gray;
  border-color: $lighten-gray;
  filter: none; // remove browser firefox background on autocomplete

  &::placeholder {
    font-size: 16px;
    color: $dark-gray;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $lighten-gray;
  }

  // remove browser Chrome background on autocomplete
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $lighten-gray inset;
    box-shadow: 0 0 0px 1000px $lighten-gray inset;
  }
}

.ant-input-affix-wrapper {
  background-color: $lighten-gray;
  border-radius: 0.8rem;

  &:hover {
    background-color: $lighten-gray;
  }
  .ant-input-suffix,
  .ant-input-prefix {
    background-color: transparent;
  }
}

.ant-form-item-has-error .ant-input {
  background-color: $light-red;
}

.ant-form-item-explain.ant-form-item-explain-error {
  margin-left: 14px;
}

.ant-input-password {
  padding-top: 0;
  padding-bottom: 0;

  &:hover .ant-input-suffix,
  &:active .ant-input-suffix,
  &:focus .ant-input-suffix {
    background-color: $lighten-gray;
  }
}

.ant-form-item-has-error .ant-input-password {
  input {
    background-color: #ffffff;
  }

  &:hover .ant-input-suffix {
    background-color: #ffffff;
  }
}

.ant-form-item-has-error {
  .ant-input-affix-wrapper {
    &.ant-input-password {
      background-color: $light-red;

      .ant-input {
        background-color: $light-red;
      }
    }

    &.ant-input-affix-wrapper-focused {
      &.ant-input-password {
        background-color: #ffffff;
      }

      .ant-input {
        background-color: #ffffff;
      }
    }

    &:hover {
      .ant-input-suffix {
        background-color: $light-red;
      }
    }
  }

  .ant-input-affix-wrapper-focused {
    &:hover {
      .ant-input-suffix {
        background-color: #ffffff;
      }
    }
  }
}
