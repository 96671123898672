@import '../../../styles/mixins/index';
@import '../../../styles/constants.scss';

.modal {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0;
  top: 32px;
  margin: 0;
  padding: 0;

  @include media-breakpoint-xs(up) {
    bottom: auto;
    left: auto;
    top: 40px;
    right: 10px;
    padding: 0 0 24px;
  }
}

.modalBody {
  padding: 5px 5px 12px 12px;
  overflow: visible;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 140%;
  min-height: 876px;
  @include background-lines;
  width: 100%;

  h1 {
    color: $rosatom-deep-blue;
    font-size: 1.7rem;
    margin-bottom: 2.5rem;
    @include media-breakpoint-md(up){
      font-size: 2.8rem;
      margin-bottom: 1rem;
    }
  }

  @include media-breakpoint-xs(up) {
    width: 497px;
  }  

  @include media-breakpoint-md(up){
    padding: 20px 24px 24px 32px;
   
  }

  @include media-breakpoint-md(up) {
    padding: 16px 12px 12px 32px;
    
  }

  @include media-breakpoint-lg(up) {
    width: 624px;
  } 
}

.modal-h1 {
  font-size: 17px;
  color: $rosatom-deep-blue;
  @include media-breakpoint-md(up){
    font-size: 32px;
  }
}

.swipeHolder {
  position: relative;
  width: 100%;
  height: 18px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - 25px);
    width: 50px;
    height: 4px;
    border-radius: 2px;
    background-color: $secondary;
  }

  @include media-breakpoint-md(up) {
    display: none;
    visibility: hidden;
  }
}