section.ant-layout {
  height: auto;
  background-color: transparent;
}

header.ant-layout-header {
  height: 82px;
  padding: 0;
  color: inherit;
  line-height: 60px;
  background: transparent;
  position: relative;
  &:before {
    $cast-width: #{'calc(290px + (100vw - 1172px) / 2)'};
    position: fixed;
    background-color: $bg;
    width: $cast-width;
    content: '';
    top: -1rem;
    left: 0;
    right: 0;
    height: 3rem;
    z-index: 7;
  }
}

aside.ant-layout-sider {
  background: transparent;
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  .ant-layout-sider-children {
    position: relative;
    padding-bottom: 50px;
  }
}

main.ant-layout-content {
  $clamp-height: #{'calc(100vh - 40px)'};
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 10px 12px 10px 12px;
  min-height: $clamp-height;
}

main.ant-layout-content {
  padding-bottom: 86px;
  div.ant-col.main-content {
    @media (min-width: $md) and (max-width: $lg - 1) {
      flex: 0 0 62%;
      max-width: 62%;
    }
  }
  div.ant-col.side-content {
    @media (min-width: $md) and (max-width: $lg - 1) {
      flex: 0 0 38%;
      max-width: 38%;
    }
  }
}

footer.ant-layout-footer {
  z-index: 7;
  position: relative;
  padding: 0;
}
