
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  &>img {
    height: 44px;
    margin-right: 10px;
  }
  &>div {
    width: 100%;
  }
  input {
    text-transform: uppercase;
  }
}

.error {
  border-color: #ff4d4f !important;
}

.errorMessage {
  position: absolute;
  margin-top: 44px;
}