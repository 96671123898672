.wrapper {
  position: relative;
  aside>nav {
    display: none !important;
  }
}

.show {
  .overlay {
    visibility: visible;
    opacity: 1;
  }
}

.overlay {
  display: flex;
  transition: 0.2s;
  opacity: 0;
  position: absolute;
  visibility: hidden;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin: 10px 0;
  top: 100%;
}

