@import "../../../../styles/constants";
@import "../../../../styles/mixins/index";

.boxVideo {
  display: flex!important;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: calc(100vh - 130px);
  width: 100%;

  @media (min-width: $md) {
    height: 75vh;
  }

  @media (min-width: $md) and (max-height: 700px) {
    height: calc(100vh - 165px);
  }

  @media (min-width: $xl) {
    height: 624px;
  }

}

