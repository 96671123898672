@import "../../../styles/constants";
@import "../../../styles/mixins/index";

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 12px;
  color: #FFFFFF;
  position: fixed;
  width: calc(100% - 16px);
  bottom: 0;
  animation: appearance  3s; 

  @media (min-width: $md) {
    margin-bottom: 13px;
    width: 100%;
    position: static;
    color: #000000;
  }
}

.popupGalleryMask {
  overflow: visible;

  @media (min-width: $lg) {
    top: -10px;
    overflow: auto;
  }
}

.boxSpan {
  display: flex;
  align-items: center;
}

.headerTitle {
  @media (max-width: $lg) {
    max-width: 73vw;
  }
}

.headerTitleFullwidth {
  max-width: 100%;
}

.delimiter {
  width: 1px;
  height: 14px;
  margin: 0 6px;
  background: #FFFFFF;

  @media (min-width: $md) {
    background: #000000;
  }
}

.total {
  flex-shrink: 0;
  white-space: nowrap;
  overflow: hidden;
}

.album {
  flex-shrink: 6;
  max-width: 60vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
}

.albumFullwidth {
  max-width: 100%;
}

.navigation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boxSkeletonImage {
  display: flex!important;
  justify-content: center;
  align-items: center; 
  border-radius: 4px;
  height: 487px;
  width: 100%;
  
  @media (min-width: $md) {
    height: 591px;
  }

  @media (min-width: $xl) {
    height: 624px;
  }
  
  @media (max-height: 620px) {
    height: calc(100vh - 130px);
  }
}

@keyframes appearance {
  from {opacity: 0}
  to {opacity: 1;}
  }
