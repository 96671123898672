@import '../../styles/mixins/index';
@import '../../styles/constants.scss';

.icon {
  cursor: pointer;
}

.modal {
  width: calc(100vw - 1.6rem) !important;
  top: 1rem;
  position: absolute;
  left: .8rem;

  @include media-breakpoint-sm() {
    width: 49.8rem !important;
    left: calc(50% - 49.8rem / 2);
  }
  @include media-breakpoint-md() {
    top:  15px;
    width: 56.4rem !important;
    left: calc(50% - 56.4rem / 2);
  }
}

.modalBody {
  padding: 5px 5px 12px 12px;
}

