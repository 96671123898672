@import '../../../../../styles/base';
@import '../../../../../styles/colors';

.modal {
  h4 {
    font-size: 1.7rem;
    margin-bottom: $margin-sm;
  }

  header {
    margin-bottom: $margin-sm;
    border-bottom: 1px solid $light-gray;
  }

  footer {
    display: flex;
    align-items: center;
    padding-top: $padding-sm;
    margin-top: $margin-sm;
    border-top: 1px solid $light-gray;
  }

  section {
    width: 100%;
  }

  button {
    margin-right: $margin-sm;
  }
}

.notificationsConfig {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid $light-gray;
}

.notificationsTitle {
  display: block;
  margin-bottom: 4px;
}

.notificationsSubtitle {
  display: block;
  color: $dark-gray;
  margin-bottom: 12px;
}