@import "../../../styles/constants";

.ant-typography h1, h1.ant-typography {
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.2em;
  font-weight: $fw-regular;
  color: $blue;
  text-transform: uppercase;
  color: $rosatom-light-blue;
  padding-bottom: 1rem;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid #E6EFF6
}

.ant-typography h2, h2.ant-typography {
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 100%;
  // &--title-group {
  //   margin-bottom: 2rem;
  //   line-height: 1.3em;
  //   color: $rosatom-blue;
  //   font-size: 2rem;
  // }
  color: $rosatom-deep-blue;
}

.ant-typography h3, h3.ant-typography {
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 120%;
}

.ant-typography h4, h4.ant-typography {
  font-weight: bold;
  font-size: 2.0rem;
  line-height: 120%;
  color: $rosatom-deep-blue;
}


.ant-typography h5, h5.ant-typography {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 125%;
}

.ant-typography {
  font-size: 1.4rem;
  & > a:hover {
    text-decoration: underline;
  }
  &.xxl {
    font-size: 3.2rem;
  }
  &.xl {
    font-size: 1.7rem;
  }
  &.lg {
    font-size: 1.6rem;
  }
  &.sm {
    font-size: 1.2rem;
  }
}

