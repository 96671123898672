@import '../../../styles/constants.scss';

.form-recovery {
  .ant-btn-primary {
    background-color: $rosatom-light-blue;
  }
  button {
    padding: 12px 13px;
  }

  button > span {
    font-size: 1.3rem;
  }
}