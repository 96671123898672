@import "../../../../styles/mixins/index";
@import "../../../../styles/constants.scss";
@import "../../../../styles/mixins/media-queries.scss";

.wrapper, .empty {
  background-color: $white;
  border-radius: 10px;
  width: 100%;
  margin-top: 8px;
  padding: $padding-sm $padding-xs;
  

  @include media-breakpoint-md() {
    margin-top: 20px;
  }
}

.wrapper {
  max-height: calc(100vh - 120px);
  overflow: hidden;
  padding-right: 0;
}

.empty {
  margin-left: 0;
  margin-right: 0;
  & > p {
    color: $gray;
  }
  & > div {
    margin: 1rem;
    height: auto;
  }
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 145px);
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $rosatom-very-light-gray;
    border-radius: 4px;
  }
}
