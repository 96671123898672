@import "../../../../styles/constants";
@import "../../../../styles/mixins/media-queries";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;

  @include media-breakpoint-xl(up) {
    flex-direction: row;
    align-items: center;
    padding-top: 0;
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  transition: all 300ms;

  &:hover {
    text-decoration: underline;
    color: #333;
  }

  @include media-breakpoint-xl(up) {
    flex-direction: row;
    align-items: center;
  }
}

.userinfo {
  margin: $margin-md 0;
  display: flex;
  flex-direction: column;
  line-height: $height-unit * 2;
  min-width: $width-unit * 20;
  max-width: $width-unit * 22;
  @include media-breakpoint-xl(up) {
    margin: 0 $margin-md;
  }
}

.userinfo_name {
  line-height: $height-unit * 2.5;
  font-size: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userinfo_role {
  line-height: $height-unit * 2.5;
  font-size: 1.5rem;
  opacity: .5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.userinfo_rate {
  margin-left: $margin-xs;
  vertical-align: middle;
}

.usermenu_btn {
  //margin-top: $margin-xs;
  line-height: initial;
}
