@import '../../../styles/constants';

.ant-tabs.ant-tabs-custom {

  & .ant-tabs-nav {

    & .ant-tabs-ink-bar {
      width: 0!important;
    }

    &::before {
      border-bottom: none;
    }
  }

  & .ant-tabs-tab {
    padding: 0;
    color: $rosatom-light-blue;
    font-weight: bold;
    margin-right: 25px;
  }

  & .ant-tabs-tab-active {
    background-color: $rosatom-very-light-gray;
    padding: $padding-xss 1rem;
    border-radius: $border-radius;

    .ant-tabs-tab-btn {
      color: $rosatom-light-blue;
      font-weight: bold;
    }
  }
}
