@import "../../../styles/constants";

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fileName {
  margin-bottom: 2px !important;
  font-size: 1.2rem !important;
  line-height: 1.3em !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.thumbContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  background-color: $rosatom-very-light-gray;
  border-radius: 10px;
  flex: 0 0 40px;
  cursor: pointer;
}

.small {
  width: 40px;
  height: 40px;
}

.big {
  width: 250px;
  height: 250px;
}

.thumbPic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 1rem;
}

.description {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downloadIcon {
  transform: rotate(0.25turn);
}

.spin {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.acceptDialog {
  display: flex;
}

.accept {
  margin-right: 12px;
  padding: 0;

  span {
    color: $red;
    font-size: 1.2rem;
    line-height: 1.3em;
    font-weight: $fw-regular !important;
  }
}

.cancel {
  padding: 0;

  span {
    color: $rosatom-green;
    font-size: 1.2rem;
    line-height: 1.3em;
    font-weight: $fw-regular !important;
  }
}

.link {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
