@import "../../../../styles/constants";
@import "../../../../styles/mixins/index";

.wrapper {
  z-index: 99;
  position: absolute;
  background-color: rgba(166, 204, 237, 0.8);
  top: -1rem;
  left: -1rem;
  overflow: hidden;
  height: 0;
  .block {
    position: relative;
    float: right;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 260px;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.2s, opacity 0.2s 0.2s;
    background-color: white;
    border-radius: $border-radius;
    ul[role=menu] {
      display: block;
      background: none;
      border: 0;
      box-shadow: none;
      position: relative;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
      min-height: auto;
      height: auto;
    }
  }
}

.active {
  height: 100vh;
  width: 100vw;
  .block {
    --max-menu-height: Calc(100vh - 2rem);
    height: var(--max-menu-height);
    opacity: 1;
    transition: height 0.2s 0.2s, opacity 0.2s;
  }
  @include media-breakpoint-xl(up){
    display: none;
  }
}

.authBtnWrapper {
  
  margin: 1rem;
  text-align: center;
  border-bottom: 1px solid $rosatom-very-light-gray;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.menuWrapper {
  overflow-y: auto;
  overflow-x: hidden;
}