@import "../../../styles/constants";

.btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
}
