@import "../../../styles/constants";
@import "../../../styles/mixins/media-queries";

.swipeBackdrop {
  position: fixed;
  z-index: 99;
  background-color: rgba(166, 204, 237, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.swipeContainer {
  position: fixed;
  left: 0px;
  right: 0px;
  padding: 24px 16px 16px;
  border-radius: $border-radius;


  transition: 0.2s;
  background-color: white;
  z-index: 8;
}

.swipeContainer:before {
  width: 50px;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  background-color: #B8C1CC;
  content: '';
  top: 5px;
  margin-left: -25px;
  left: 50%;
}