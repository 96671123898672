@import "../../../styles/constants.scss";
@import "../../../styles/mixins/media-queries.scss";

.form {
  margin-top: 28px;
  padding-right: 10px;
  padding-left: 2px;
  
  @include media-breakpoint-md(up) {
    padding-right: 0px;
  }
}

.spin {
  top: calc(50% - 200px)!important;
}

.recovery {
  font-size: 1.2rem;
  color: $rosatom-deep-blue;
  display: inline-block;
  text-align: right;
  width: 45%;
  max-width: 164px;
  max-height: 90px;
  cursor: pointer;
  position: absolute;
  top: 78px;
  left: 55%;
  z-index: 1;
  overflow: hidden;
}

.button {
  margin-top: 13px;
  padding: 12px 30px;
}

.passwordBox {
  position: relative;
}