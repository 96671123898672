@import '../../../styles/constants';
@import '../../../styles/mixins/media-queries';

.wrapper {
  background-color: $white;
  border-top: 1px solid $light-gray;
  bottom: -50px;
  padding: 0;
  display: none;
  height: 40px;
  a {
    color: $gray2;
    white-space: nowrap;
    padding: 0 10px;
  }
  article {
    color: $gray2;
    white-space: nowrap;
    padding: 0 10px;
  }
  .digitalAtomRow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
  }
  .digitalAtom {
    font-family: 'Gilroy';
    font-size: 12px;
    color: $rosatom-light;
    & > span {
      font-size: 14px;
      font-weight: bold;
    }
    &:hover {
      text-decoration: none;
    }
  }
  .feedback {
    cursor: pointer;
  }

  @media (max-width: ($xl - 1)) {
    display: block;
    height: 96px;

    .digitalAtomRow {
      height: 52px;
    }
  }

  @media (max-width: ($md - 1)) {
    height: auto;
  }

  
  @media (max-width: ($sm - 1)) {
    .digitalAtomRow {
      height: 90px;
      padding-bottom: 10px;
      flex-direction: column;
      align-items: flex-start;

      & > span {
        padding: 10px 0 8px;
      }
    }
  }

  @media (max-width: ($xs - 1)) {
    .digitalAtomRow {
      height: auto;
    }

    a {
      white-space: normal;
    }
  }
}

.sticked {
  display: block;
  position: fixed;
  bottom: -16px;
  width: 100vw;
  transition: bottom 0.3s;
  overflow-x: scroll;
  height: 96px;
}

.container {
  max-width: 1172px;
  margin: 0 auto;
}

.infoBlock {
  height: 40px;
  display: grid;
  grid-template-columns: repeat(7, auto);

  @media (max-width: ($xl - 1)) {
    height: 44px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ($md - 1)) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    height: 88px;
  }

  @media (max-width: ($xs - 1)) {
    height: auto;
  }
}

.divider {
  width: 1px;
  height: 24px;
  background-color: $light-gray;
  border-radius: 2px;
}

.menuCol {
  max-width: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }

  @media (max-width: ($xl - 1)) {

    &, &:first-child, &:last-child {
      justify-content: flex-start;
    }

    &:nth-child(even) {
      display: none;
    }
  }
}

.modal {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 32px;
  margin: 0;
  padding: 0;

  @include media-breakpoint-xs(up) {
    bottom: auto;
    left: auto;
    top: 40px;
    right: 10px;
    padding-bottom: 24px;
  }
}
