@import "../../../../../../../styles/constants";

.lineContainer {
  position: relative;
  border-radius: $border-radius;
  padding: 10px 17px;
  background-color: $white;
  transition: all .3s ease;
}

.readOnly {
  opacity: 0.7;
}

.isChoicesReadOnly {
  background-color: $rosatom-very-light-gray;
}

.percentsWrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
}

.percentsContainer {
  position: relative;
  height: inherit;
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.text {
  color: $dark-gray;
}
