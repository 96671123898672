@import "../../../../styles/constants";

.ant-collapse .ant-collapse-item {
  border-radius: $border-radius;
  background-color: $white;
  margin-bottom: 20px;

  &:last-child {
    border-radius: $border-radius;
  }

  &:hover {
    box-shadow: $element-dark-shadow;

    & svg {
      color: $rosatom-light-blue;
    }
  }

  & svg {
    color: $gray;
    width: 16px;
    height: 16px;
  }

  & p {
    margin: 0;
  }
}

