.ant-radio-group-solid {
  display: flex;
  flex-direction: column;
}

.ant-radio-group {
    display: flex;
    flex-direction: column;
}

  label.ant-radio-wrapper {
    font-size: 15px;
    height: 46px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    &.ant-radio-wrapper-checked {
      background-color: #E6EFF6;
      border-radius: 12px;
    }

    .ant-radio {

      .ant-radio-input{
        display: none;
      }
      .ant-radio-inner {
        height: 22px;
        width: 22px;
        border: 2px solid #818C99;
      }

      .ant-radio-inner:after {

      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border: 2px solid #309BEC;

          &:after {
            height: 22px;
            width: 22px;
            top: -2px;
            left: -2px;
            opacity: 1;
            transition: none;
            margin: 0;
            transform: none;
            border-radius: 11px;
            border: 4px solid transparent;
            background-clip: padding-box;
          }
        }
      }
    }
  }


label.ant-radio-button-wrapper {
  position: relative;
  background-color: transparent;
  border: none;
  border-left: none !important;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 15px;
  &:focus-within {
    box-shadow: none !important;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    width: 0 !important;
    background-color: #003274 !important;
    border-radius: 1000px;
    border: none;
    padding: 0;
  }

  &.ant-radio-button-wrapper-checked {
    background: transparent !important;
    color: #003274 !important;

    &:before {
      width: 4px !important;
      transition: 0.2s;
    }
  }
}
