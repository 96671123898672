@import "../../../styles/constants";

.linkCardWrapper {
  box-sizing: border-box;
  min-width: 300px;
  max-width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  border-radius: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:hover {
    h3 {
      overflow: auto;
      overflow-x: hidden;
      white-space: normal;
      text-overflow: unset;
    }
  }
}

.titleWrapper {
  margin-bottom: 8px;
  width: 276px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  border-radius: 1rem;
  background-color: white;
}

.title {
  margin-bottom: 0;
  color: $dark-gray;
  font-size: 12px;
  font-weight: $fw-bold;
  line-height: 1.3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rightElementWrapper {
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-left: .5rem;
  border-left: 1px solid $dark-gray;
}
