sup.ant-scroll-number.ant-badge-count {
  background: #4BB34B;
  border-radius: 9px;
  height: 18px;
  width: 18px;
  min-width: 18px;
  min-height: 18px;
  top: 6px;
  right: 6px;
  font-size: 11px;
}
sup.ant-badge-multiple-words {
  padding: 0;
}
