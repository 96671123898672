
$selected-white:#F9FBFF;
$very-light-gray-s: #EFF0F6;
$very-light-gray-selected-s: #e6e7ee;
$light-gray-s: rgb(160, 163, 189);
$very-light-blue-s: #D9DBE9;
$light-small-blue-s: #dae8ff;
$light-blue-s: #E0F2FF;
$light-blue-selected-s: #cfdeec;
$gray-s: #6e7191;
$dark-gray-s: #4E4B66;
$dark-blue-s: #262338;
$red-s: #E01010;
$light-red: #FAEBEB;
$light-red-s: #fcd8d8;
$soft-red: #F2CCCC;
$fail-red: #d97676;
$red-dark-s: #B00000;
$blue-lite-s:#118EFF;
$correct-blue: #a1d2ff;
$blue-lite-selected-s: #1473F2;
$vanilla-green: #EBFFE3;
$green: #CDEEC8;
$color-white-gray: #F7F7FC;


$index: calc(1vh + 1vw);
$size15: 15px;//clamp(15px,calc(#{$index} / 1.56),22px);
$size12: 12px;//clamp(12px,calc(#{$index} / 1.6),18px);
$size11: 11px;//clamp(11px,calc(#{$index} / 2.1),16px);

%surveyScroll{
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #BCBED2;
  }
}

@mixin showLines($count, $font: 700) {
  font-weight: $font;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $count;
  overflow: hidden;
}

