@import "../../../styles/constants";

.ant-btn.ant-btn-link.btn-delete {
  padding: 0;
  span {
    font-size: 1.2rem;
    line-height: 1.3em;
    font-weight: $fw-regular;
  }
}
