@import "../../../styles/constants";

.ant-btn {
  border-radius: $border-radius;
  padding: $padding-sm $padding-lg;
  height: unset;
  line-height: 1.3rem;
  background-color: $rosatom-very-light-gray;
  border: none;
  color: $rosatom-light-blue;
  &>span {
    font-size: $button-font-size;
    line-height: 2rem;
    font-weight: 700;
  }
  &.ant-btn-sm {
    padding: $padding-xs $padding-sm;
    line-height: 1.6rem;
    &>span {
      line-height: 1.6rem;
    }
  }

  &.ant-btn-dangerous{
    background-color: $light-red;
    color: $red;
    &:focus, &:active, &:hover{
      background-color: $light-red-selected;
      color: $red;
    }
  }

  &:focus, &:active {
    background-color: $rosatom-very-light-gray-selected;
    border-color: $rosatom-very-light-gray-selected;
    color: $rosatom-light-blue;
  }
  &:hover {
    background-color: $rosatom-very-light-gray-selected;
    border-color: $rosatom-very-light-gray-selected;
    color: $rosatom-light-blue;
  }
  &[disabled] {
    background-color: $light-gray;
    &:hover, &:active, &:focus {
      background-color: $light-gray;
    }
  }

  &.ant-btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    &>span {
      font-weight: 700;
    }
    &:focus, &:active {
      background-color: $primary-active;
      border-color: $primary-active;
      color: $white;
    }
    &:hover {
      background-color: $primary-active;
      border-color: $primary-active;
      color: $white;
    }
    &[disabled] {
      background-color: $dark-gray;
    }
  }

  &.ant-btn-link {
    background-color: transparent;
    border-color: transparent;
    color: $primary;
    & span {
      font-weight: 700;
    }
    &:focus, &:active {
      color: $primary-active;
      span {
        text-decoration: underline;
      }
    }
    &:hover {
      color: $primary-active;
      span {
        text-decoration: underline;
      }
    }
    &[disabled] {
      color: $dark-gray;
    }
  }

  &.ant-btn-ghost {
    background: transparent;
    border: none;
    box-shadow: none;
    width: fit-content;
    height: fit-content;
    padding: 0;
  }
}

a.ant-btn {
  padding-bottom: $padding-sm ;
  padding-left: $padding-md;
  padding-right: $padding-md;
  padding-top: $padding-sm !important;
  line-height: 2rem;
}
