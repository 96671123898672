@import "../../../styles/constants";
@import "../../../styles/mixins/media-queries";


.block_body {
  padding-top: 10px;
}


.wrapper {
  position: relative;
  display: block;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 10px;

  @include media-breakpoint-md() {
    margin-bottom: 0;
  }
}

.wrapper_mobile {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-md() {
    display: block;
  }
}

.accept {
  width: 100%;
  margin-top: 10px;

  span {
    font-size: 16px;
  }
}

.dropdown {
  display: none;

  h4 {
    font-size: 17px;
    padding-bottom: 16px;
  }

  & > button {
    background-color: white;
    padding: 12px 8px;
    width: 40px;
    height: 40px;
  }
}

.dropdown_mobile {
  display: block;
  @include media-breakpoint-md() {
    display: none;
  }
}

.radio {
  display: flex;
  flex-direction: column;

  label {
    font-size: 15px;
  }
}

.radio_buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;

  @include media-breakpoint-md() {
    margin-top: 2px;
  }
}

.radio_buttons_mobile {
  display: none;

  @include media-breakpoint-md() {
    display: flex;
  }
}

.radio_active {
  background-color: white !important;
}

.sticky {
  position: fixed;
  top: 90px;
  @include media-breakpoint-xl(up) {
    top: 10px;
  }
}
