@import '../../../styles/constants.scss';

.ant-select {

  input {
    min-height: 44px;
  }
  
  &.ant-select .ant-select-selector {
    border-color: $lighten-gray;
    border-radius: 8px;
    background-color: $lighten-gray;
  }
  
  &.ant-select .ant-select-selector, 
  &.ant-select .ant-select-selection-item {
    line-height: 44px; 
    min-height: 44px;
  }
  
  &.ant-select .ant-select-selection-placeholder {
    font-size: 16px;
    color: $dark-gray;
    line-height: 44px;
  }

  .ant-select-selection-item {
    font-size: 16px;
  }
}

.ant-form-item-has-error {
  &:not(.ant-select-borderless):not(.ant-select-customize-input) {
    .ant-select {
      .ant-select-selector {
        background-color: $light-red;
      }
    }
  }
}

