@import "../../../styles/constants";
@import "../../../styles/mixins/index";

.header {
  --max-content-width: Calc(100% - 2rem);
  &>div {
    display: block;
    background: #FFFFFF;
    box-shadow: $block-shadow;
    border-radius: $border-radius;
    padding: $padding-sm $padding-xl;
    z-index: 10;
  }
  margin: $block-margin;
  transition: .4s;
  position: fixed;
  width: 1152px;
  max-width: var(--max-content-width);
  z-index: 8;
  overflow: hidden;
  padding-bottom: 2px;

  @include media-breakpoint-xl(down) {
    overflow: visible;
    .authBtnWrapper {
      display: none;
    }
    .mobileView {
      display: flex;
      align-items: center;
    }
    &:before {
      position: absolute;
      background-color: $bg;
      width: 100%;
      content: '';
      top: -1rem;
      left: 0;
      right: 0;
      height: 2rem;
      z-index: 9;
    }
    &>div {
      position: absolute;
      left: 0;
      right: 0;
    }
  }

}

.sticked {
  position: fixed;
  width: 270px;
  .authBtnWrapper {
    opacity: 0;
    transition: opacity 0.3s;
  }
  &:hover {
    width: Min(var(--max-content-width), 1152px);
    .authBtnWrapper {
      opacity: 1;
      transition: opacity 0.3s 0.3s;
    }
  }
  @include media-breakpoint-xl(down) {
    width: var(--max-content-width);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
}

.logoWrapper {
  margin-right: auto;
  width: 134px;
  height: 38px;
  a {
    display: flex;
    svg {
      width: 38px;
    }
  }
}

.logoText {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  border-left: 1px solid $gray;
  padding-left: 5px;
  span {
    height: 12px;
    line-height: 28px;
    text-transform: uppercase;
    &:last-child {
      color: $rosatom-blue;
    }
  }
}

.authBtnWrapper {
  display: flex;
  justify-content: space-between;
  transition: opacity 0.1s 0.3s;
}

.mobileView {
  display: none;
}

.box_spin {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  top: 3px;
  width: 100px;
  height: 43px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 15px;
  box-sizing: border-box;
}
