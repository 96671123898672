@import "../../../../styles/constants";
@import "../../../../styles/mixins/index";
@import "../../../../components/survey/const";

.header{
  width: 100%;
  transition: .4s;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  padding-bottom: 2px;
  height: 66px;

}
.editTitle{
  width: 100%;
  input {
    background-color: $very-light-gray-s!important;
  }
}
.logoWrapper {
  margin-left: 8px;
  width: 62px;
  height: 38px;
}
.titleHead{
  display: -webkit-box;
  margin: 0;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-weight: 700;
  font-size: 24px;
  width: 100%;
  line-height: 134%;
  color: $rosatom-deep-blue;
  text-overflow: fade;
}
.authBtnWrapper {
  display: flex;
  justify-content: space-between;
  transition: opacity 0.1s 0.3s;
  & > a {
    display: flex;
    margin-right: 10px;
  }
}
.content {
  display: flex;
  background: #FFFFFF;
  box-shadow: $block-shadow;
  border-radius: $border-radius $border-radius 0 0;

  z-index: 10;
  justify-content: space-between;
  align-items: center;
  min-height: 66px;
  border-bottom: $very-light-blue-s 1px solid;
}

.sticked {
  position: fixed;
  .authBtnWrapper {

    transition: opacity 0.3s;
  }
  &:hover {
    .authBtnWrapper {
      opacity: 1;
      transition: opacity 0.3s 0.3s;
    }
  }
}

.arrow{
  font-size: 24px;
  color: black;
}
.arrowContainer{
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  display: flex;
  border-radius: 8px;
  transition: background ease-out 0.5s;
  height:40px;
  align-items: center;
  &:hover {
    background: $very-light-gray-selected-s;
  }
}
