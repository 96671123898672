@import '../../../../styles/constants';
@import '../../../../styles/base';

.ant-breadcrumb {
  min-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  & > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:first-child {
      .ant-breadcrumb-link {
        font-weight: bold;
      }
    }

    &:last-child {
      .ant-breadcrumb-link {
        color: $rosatom-deep-blue !important;
        cursor: default;
      }
    }
  }

  .ant-breadcrumb-link {
    color: $rosatom-light-blue;
    font-size: 2rem;
    font-weight: normal;
    line-height: 40px;
    cursor: pointer;
  }

  .ant-breadcrumb-separator {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.ant-modal-body {
  padding: $padding-lg $padding-md;
}

.ant-modal-close {
  top: 6px;
  right: 0px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: left;

  > .ant-btn-dashed {
    background-color: $rosatom-light-blue !important;
    color: $white !important;
  }
}
