.ant-list-item-meta-content {
  max-width: 100%;
}

.ant-list-item-meta-title {
  font-size: 1.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  & a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}