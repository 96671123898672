@import "../../../styles/mixins/media-queries";
@import '../../../styles/mixins/background-lines';

.block {
  textarea {
    resize: none;
  }
  @include background-lines;
  background-position-y: bottom;
  background-size: cover;
  background-position-x: right;
}

.hidden {

}

.contact {
  padding: 4px 11px;
  color: black;
  font-size: 14px;
  line-height: 2.5715;
  margin-top: -56px;
  margin-bottom: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.groupButtons {
  button {
    width: 100%;
    @include media-breakpoint-md(up) {
      width: auto;
    }
  }


}