@import "../../../styles/constants";
@import "../../../styles/mixins/media-queries.scss";

.ant-modal {

  @include media-breakpoint-md(down) {
    max-width: 100%;
  }

  &-body {
    padding: 0;
  }

  &-content {
    border-radius: $border-radius;
  }

  &-close {
    top: 19px;
    right: 17px;
  }

  &-mask {
    background-color: rgb(166, 204, 237);
    @include media-breakpoint-md(up) {
      background-color: rgba(166, 204, 237, .8);
    }
  }

  &-confirm-body-wrapper {
    line-height: 1;
  }

  .ant-modal-confirm-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .ant-modal-confirm-title  {
      font-size: 2rem;
      font-weight: 700;
    }

    .ant-modal-confirm-content {
      margin-left: 0!important;
      line-height: 2rem;
    }

    .anticon {
      margin-top: 8px;
      margin-bottom: 20px;

      svg {
        height: 52px;
        width: 52px;
      }
    }
  }
}

.ant-modal-confirm {
  .ant-modal-body {
    padding: 24px;
  }
}

.ant-modal-confirm-success {
  .ant-modal-confirm-body {
    .anticon-check-circle {
      svg {
        color: $rosatom-green;
      }
    }
  }
}

.ant-modal-confirm-confirm {
  .ant-modal-confirm-body {
    .anticon-check-circle {
      svg {
        color: $yellow;
      }
    }
  }
}

.ant-modal-confirm-error {
  .ant-modal-confirm-body {
    .anticon-close-circle {
      svg {
        color: $red;
      }
    }
  }
}

