@import '../../../styles/constants';

.wrapper {
  width: 100%;
  max-width: 344px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  background: $white;
  border-radius: $border-radius;

  & h2 {
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.38px;
    margin-bottom: 0;
  }
}

.divider {
  margin: 10px 0 12px 0;
  border-color: $light-gray;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    cursor: pointer;
    svg {
      transform: rotate(45deg);
    }
  }
}
