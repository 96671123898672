.block {
  height: 78vh;
  min-height: 556px;
}

p {
  margin-top: 5px;
  margin-bottom: 35px;
}

.groupButtons {
  margin-top: 36px;

}

.buttonCancel {
  margin-left: 10px;
}
