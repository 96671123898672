@import "../../../styles/constants";
@import "../../../styles/mixins/media-queries";

.container {
  padding: 20px 16px;
  border-radius: $border-radius;
  box-shadow: $block-shadow;
  background-color: $white;
  .title {
    border-bottom: 1px solid $lighten-gray;
  }
  .body {
    min-height: 4rem;
  }

  @include media-breakpoint-md(down) {
    &.collapsed {
      .body {
        overflow: hidden;
      }
    }

    .readMoreContainer {
      display: flex;
      justify-content: center;
      margin-bottom: -14px;

      button {
        text-transform: uppercase;
      }
    }
  }
  @include media-breakpoint-md(up) {
    .readMoreContainer {
      display: none;
    }
  }
}