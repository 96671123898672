@import "../../../../styles/index";

.customIcoMenu.ant-menu
{
  width: 52px;
  margin: 0;
  height: 100%;
  padding: 0;
  border-radius: 0;
  max-height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-menu-item:not(.item-line):not(.item-line){
    padding: 8px!important;
    writing-mode: vertical-rl;
  }
  @include media-breakpoint-xl(down) {
    display: block;
  }
}

.item-line.ant-menu-item{
  padding: 0!important;
  border-style: none;
  border-top: 1px solid #D9DBE9;
  max-width: 40px;
  margin: auto;
  width: 100%;
  height: 2px;
  max-height: 2px!important;
}

