@import '../../../styles/constants.scss';

.popup-gallery {
  .slick-next,
  .slick-prev {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: calc(50% - 65px);

    svg {
      width: 33px;
    }
  }

  .slick-next {
    right: -80px;

    @media (min-width: $xl) {
      right: -117px;
    }
  }

  .slick-prev {
    left: -90px;

    @media (min-width: $xl) {
      left: -117px;
    }
  }

  .slick-arrow {
    visibility: hidden;
    @media (min-width: $lg) {
      visibility: visible;
    }
  }

  // carousel
  .removePrevArrow {
    .slick-prev {
      display: none !important;
    }
  }

  // styles for Modal component
  // I used !important because it is a property in inline
  &.ant-modal {
    max-width: 1152px;
    width: 100% !important;
    top: 70px;
    padding-bottom: 0;

    @media (min-width: $lg) {
      top: 100px;
      width: 80% !important;
    }
  }

  .ant-modal-content {
    @media (max-width: 767px) {
      background: transparent;
      box-shadow: none;
    }

    @media (min-width: $md) {
      background: #ffffff;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .ant-modal-body {
    padding: 0 8px;

    @media (min-width: $md) {
      padding: 24px;
      padding-top: 30px;
    }
  }

  .ant-modal-close {
    left: calc(50% - 28px);
    top: -67px;

    @media (max-width: 992px) {
      top: -62px;
    }
  }

  // styles for Skeleton Image component
  .ant-skeleton-element {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .ant-skeleton-image {
    // display installed inline
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 450px;
    border-radius: 4px;
    width: 100%;
    cursor: default;

    @media (min-width: $md) {
      height: 624px;
    }
  }
}
