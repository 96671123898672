@import "../../../styles/constants";
@import "../../../styles/mixins/index";

.simple-carousel {
  .ant-carousel {
    ul.slick-dots {

      & li, & button {
        width: 24px;
        height: 24px;
        background-color: $white;
      }

      & > li {
        position: relative;
        border: 1px solid transparent;

        & > button {
          border-radius: 50%;
          opacity: 1;

          &::before {
            content: '';
            cursor: pointer;
            position: absolute;
            top: calc(50% - 2px);
            left: calc(50% - 2px);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $rosatom-light-blue;
          }
        }
      }

      & > li.slick-active {
        & > button {
          border: 1px solid $secondary;
        }
      }
    }
  }
}
