@import "../../../styles/constants";

.dates {
  padding: 4px 6px;
  background-color: $light-gray;
  border-radius: 4px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  color: $dark-gray;

  span {
    font-size: 11px;
    font-weight: $fw-bold;
    line-height: 1.3em;
  }
}
