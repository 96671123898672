@import "../../../styles/constants";

.avatar_wrapper {
  height: $width-unit * 5;
  width: $width-unit * 5;
  display: flex;
  
  img {
    width: $width-unit * 5;
    height: $width-unit * 5;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    object-position: top center;
  }
}

.small {
  height: 18px;
  width: 18px;
  img {
    height: 18px;
    width: 18px;
  }
}

.large {
  height: 56px;
  width: 56px;
  img {
    height: 56px;
    width: 56px;
  }
}