$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$space: 0.8rem;
$height-unit: 0.8rem; // 8px
$width-unit: 0.8rem;

$padding-xl: calc(#{$space} * 4);   // 32px
$padding-lg: calc(#{$space} * 3);   // 24px
$padding-md: calc(#{$space} * 2);   // 16px
$padding-sm: calc(#{$space} * 1.5); // 12px
$padding-xs: $space;       // 8px
$padding-xss: calc(#{$space} / 2);  // 4px

$margin-xl: calc(#{$space} * 4);    // 32px
$margin-lg: calc(#{$space} * 3);    // 24px
$margin-md: calc(#{$space} * 2);    // 16px
$margin-sm: calc(#{$space} * 1.5);  // 12px
$margin-xs: $space;        // 8px
$margin-xss: calc(#{$space} / 2);   // 4px


$header-height: calc(#{$height-unit} * 4.5);

$button-font-size: 1.4rem;

$block-shadow: 0 0 2px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.06);
$element-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
$element-dark-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 10px rgba(0, 0, 0, 0.16);

$block-margin: 1rem; // 10px

$border-radius: 1rem; // 10px

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

$result-panel-grid: 166px 380px 116px 144px