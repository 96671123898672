@import "../../components/survey/const";

.customSurveyLayout.ant-layout{
  width: 100%;
  max-width: none!important;
  max-height: calc(100vh - 44px);
  header{
    max-height: 66px!important;
    height: 66px;

  }
  .ant-layout-content{
    min-height: calc(100vh - 112px);
    max-height: calc(100vh - 112px);
    padding: 0;
    background-color: #FFFFFF;
    & > div{
      height: 100%;
    }
  }

}

.customSurveyLayoutFooter.ant-layout-footer{
  min-height: 44px;
  height: 44px;
}
