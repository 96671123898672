@import "../../../../components/survey/const";
.container{
  margin:0;
  max-height: 44px;
  background-color: $very-light-gray-s;
  height: 100%;
}
.menuCol {
  max-width: none;
  background-color: #FFFFFF;
  margin-left: 4px;
height: 28px;
  padding: 1px 12px;
  a{
    font-size: 12px;
    color: $dark-gray-s;
  }
  border-radius: 100px;
}
.menuColF{
  @extend .menuCol;
  margin-left: 20px;
}
.createCol{
  max-width: none;
  height: 28px;
  padding: 1px 12px;
  background-color: transparent;
  border-radius: 100px;
  border: 1px solid $light-gray-s;
  margin-left: 4px;
  a {
    font-size: 12px;
    text-decoration: none;
    color: $dark-blue-s;
    &:hover{
      text-decoration: none;
      color: $dark-gray-s;
    }
  }
}
.copy{
  margin-left: auto;
  margin-right: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: $dark-blue-s;
}
.modal {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 32px;
  margin: 0;
  padding: 0;


}
