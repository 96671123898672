.form-login {
  .ant-form-item-explain.ant-form-item-explain-error {
    z-index: 2;
    background-color: #ffffff;
  }

  .login-password__error-and-link-together {
    .ant-form-item-explain.ant-form-item-explain-error {
      width: calc(55% - 14px);
      max-width: 164px;
      margin-top: 4px;
    }
  }
}