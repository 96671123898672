@import "../../../styles/constants";
@import "../../../styles/mixins/media-queries";

.ant-upload.ant-upload-drag.drag-and-drop {
  height: 100px;
  margin-top: 1rem;
  margin-bottom: 4rem;
  background-color: #fff;
  border: 2px dashed #D7D7D8;
  border-radius: 1rem;

  .ant-empty.ant-empty-normal {
    margin: 0;
  }

  @include media-breakpoint-md(down) {
    height: 6rem;
    padding: 0;
    border: 0;
    margin-bottom: 17px;
    .ant-empty-description {
      display: none;
    }
    .ant-upload-drag-container {
      vertical-align: top;
    }
    .ant-upload {
      padding: 0;
    }

    .ant-empty.ant-empty-normal {
      margin: 0;
    }

    .ant-upload.ant-upload-btn {

    }
    .ant-upload-text {
      display: none;
    }
    .upload-bottom {
      bottom: -1rem;
    }

    div.upload-bottom-icon {
      display: block;
      margin-top: -44px;
    }
  }

  p.ant-upload-text {
    margin-bottom: 0;
    line-height: 2rem;
    color: $gray;
    position: relative;
  }
  .upload-bottom-icon {
    display: none;
  }
}

.ant-upload-list {
  .ant-upload-list-picture-container, .ant-upload-list-text-container {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.drag-and-drop {
  .upload-bottom {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    bottom: -3.2rem;
    width: 100%;
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: $fw-bold;
    color: $rosatom-blue;

    .ant-typography.upload-bottom-text {
      color: $rosatom-blue;
      margin-right: 0.5rem;
    }
  }
}
