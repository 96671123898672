@import "../../../../../../../styles/constants";
@import "../../../../../../../styles/mixins/index";

.poll-group-item-radio {
  &.ant-radio-wrapper {
    height: inherit;
  }

  &span, .ant-radio {
    top: 0;
  }

  &.ant-radio-wrapper.ant-radio-wrapper-checked {
    background-color: inherit;

    &.ant-radio-wrapper-disabled {
      &span, .ant-radio-inner {
        border-color: $rosatom-light-blue !important;
      }
      span {
        color: $black;
      }
      ::after {
        background-color: $rosatom-light-blue;
      }
    }
  }

}
