@mixin broken-image {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background-color: $rosatom-very-light-gray;
  z-index: 5;

  &::before {
    content: url('../../assets/img/image-broken.svg');
  }
}