@import "../../../styles/colors";

button.ant-switch {
  min-width: 42px;
  height: 24px;
  margin-left: 12px;
  margin-right: 8px;
  background-color: #fff;
  border: 1px solid $secondary;

  .ant-switch-handle {
    top: 0.8px;
    left: 1.5px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 10px;
    &::before {
      border-radius: 10px;
      background-color: $rosatom-very-light-gray;
      box-shadow: none;
    }
  }

  &.ant-switch-checked {
    background-color: $rosatom-light-blue;
    border-color: $rosatom-light-blue;
    .ant-switch-handle {
      left: calc(100% - 21.4px);
    }
  }
}
