@import '../../../styles/constants';

.wrapper { // For usage without modal
  width: 100%;
  max-width: 312px;
  margin-left: auto;
  margin-right: auto;

  > div {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.avatarContainer {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.avatar {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  border-radius: $border-radius * 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
  background: center / 101% no-repeat; // 101% prevents artifacts on boundaries
}

.fullNameContainer {
  margin-bottom: 5px;
  margin-top: 5px;
}

.fullName {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.325px;
}

.pictogramContainer {
  width: 72px;
  height: 72px;
  background-color: $bg;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineContainer {
  & h5 {
    margin-bottom: 0;
    color: $dark-gray;
    font-weight: 400;
    letter-spacing: -0.32px
  }
  & span {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.32px
  }
}
