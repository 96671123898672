.switcherContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 56px;
  width: 56px;
}

button.switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  &:disabled {
    cursor: auto;
  }
}
