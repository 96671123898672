@import "../../../../styles/constants";
@import "../../../../styles/mixins/index";

.boxImage {
  display: flex!important;
  justify-content: center;
  align-items: center; 
  border-radius: 4px;
  width: 100%;
  height: calc(100vh - 130px);
  
  @media (min-width: $md) {
    height: 75vh;
  }

  @media (min-width: $md) and (max-height: 700px) {
    height: calc(100vh - 165px);
  }

  @media (min-width: $xl) {
    height: 624px;
  }
}

.img {
  flex-shrink: 0;
  border-radius: 4px;
  max-width: 100%;
  max-height: 100%;
}

.brokenPicture {
  @include broken-image;
  &::before {
    width: 96px;
    height: 96px;
  }
}
