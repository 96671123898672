@import "../../../styles/index";

$menu-padding: 10px;
$menu-width: 270px;
$hover-trans-delay: 1s;

.ant-menu.custom-menu {
  margin: $block-margin 1.2rem 50px $block-margin;
  background-color: $white;
  max-height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: $border-radius;
  padding: $menu-padding 0;
  width: $menu-width;
  position: fixed;
  z-index: 1;
  &.ant-menu-sub.ant-menu-inline{
    position: relative;
    background-color: $white;
  }
  .ant-typography {
    color: $dark-gray;
    padding: 3px 0;
    transition: $hover-trans-delay;
  }



  &.ant-menu-root.ant-menu-inline {
    box-shadow: $block-shadow;
  }

  @include media-breakpoint-xl(down) {
    display: none;
  }

  li.ant-menu-item {
    margin-top: 0;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      background-color: $bg;
      border-radius: $border-radius;
      position: absolute;
      height: 46px;
      width: 8px;
      margin-left: -14px;
      opacity: 0;
      transition: 0.6s;
      z-index: -1;
    }

    &:after {
      display: none;
    }

    a .ant-typography {
      padding: 3px 0;
      color: $dark-gray;
      transition: $hover-trans-delay;
    }

    svg path {
      transition: $hover-trans-delay;
    }

    // Hover
    &:hover {

      &:before {
        transform: scaleY(1);
        opacity: 1;
      }

      &:active {
        background: unset;
      }

      a .ant-typography {
        color: $primary;
      }

      svg path {
        fill: $primary;
      }
    }

    // Selected
    &.ant-menu-item-selected.ant-menu-item-only-child{
      &:before{
        width: calc(#{$menu-width} - (34px + #{$menu-padding}));
      }
    }
    &.ant-menu-item-selected {
      background-color: transparent;

      &:before {
        opacity: 1;
        width: calc(#{$menu-width - $menu-padding * 2});
        transform: scaleY(1);
      }

      a .ant-typography {
        color: $rosatom-blue;
      }

      svg path {
        fill: $rosatom-blue
      }
    }
  }

  li.ant-menu-submenu{

    .ant-menu-submenu-arrow{
      color: $dark-gray;
    }

    .ant-menu-submenu-title {
      &:before {
        content: '';
        background-color: $bg;
        border-radius: $border-radius;
        position: absolute;
        height: 46px;
        width: 8px;
        margin-left: -14px;
        opacity: 0;
        transition: 0.6s;
        z-index: -1;
      }
    }


    .ant-menu-submenu-title:hover {
       &:before {
         transform: scaleY(1);
         opacity: 1;
       }

       &:active {
         background: unset;
       }


       span .ant-typography {
         color: $primary;
       }


       svg path {
         fill: $primary;
      }
    }


    &.ant-menu-submenu-selected {

      .ant-menu-submenu-title {
        span .ant-typography {
          color: $rosatom-blue;
        }
      }

      svg path {
        fill: $rosatom-blue;
      }
    }

  }
}

.item-line-full.ant-menu-item{
  padding: 0!important;
  border-style: none;
  border-top: 1px solid #D9DBE9;

  margin: auto;
  width: calc(100% - 16px);
  height: 1px;
  max-height: 1px!important;
}



